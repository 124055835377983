<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Iqwig">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody id="tbody_Iqwig">     
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <template v-for="(item, key) in data.data.data">
                            <tr v-for="(sub_item, sub_key) in max_row(item.iqwig)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)">
                                <td>
                                    <label class="table-check" v-if="sub_key === 0">
                                        <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                        <span class="checkbox-table"></span>
                                    </label>
                                </td>
                                <td>
                                    <router-link :to="'/detail/'+item.drug_id+'/iqwig'" v-if="sub_key === 0" target="_blank" rel="noopener">
                                        <div class="icon-fiche"></div>
                                    </router-link>
                                    <div v-if="sub_key === 0 && isAdmin()">
                                        <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                            <em class="icon-edit"></em>
                                        </a>
                                    </div>
                                </td>
                                <template v-for="(column) in data.column" :key="column.code" >


                                    <td v-if="column.Status && column.code == 'iqwig_001'" :code="column.code">
                                        <span v-html="ema_id(item.links)" v-if="sub_key === 0"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_002'" :code="column.code">
                                        <template v-if="sub_key === 0">{{item.drug_id}}</template>
                                    </td>
                                    <!-- <td v-else-if="column.Status && column.code == 'iqwig_003'" :code="column.code">
                                        <span v-html="icon_link(item.drug_id, 'iqwig')" v-if="sub_key === 0"></span>
                                    </td> -->
                                    <td v-else-if="column.Status && column.code == 'iqwig_042'" :code="column.code">
                                        <span v-if="item.current_ma_status && sub_key === 0"  v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_004'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.category)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_005'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.dci_new)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_006'" :code="column.code">
                                        <span v-html="column_status(item)" v-if="sub_key === 0"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_007'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.decision_date_num))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_008'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.ma_date))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_009'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.name)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_010'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.dci)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_011'" :code="column.code">                                
                                        <template v-if="sub_key === 0">{{check_empty(item.firm)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_012'" :code="column.code">
                                        <span v-html="check_empty(array(item.atc))" v-if="sub_key === 0"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_013'" :code="column.code">
                                        <span v-html="check_empty(array(item['indexation_'+ $i18n.locale]))" v-if="sub_key === 0"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_014'" :code="column.code">
                                        <template v-if="item.iqwig && item.iqwig.iqwig_overall_conclusion && item.iqwig.iqwig_overall_conclusion[sub_key]">
                                            <span v-if="$i18n.locale == 'fr'" v-html="iqwig_array(item.iqwig.iqwig_overall_conclusion[sub_key].iqwig_extent_probability, 'title')"></span>
                                            <span v-else v-html="iqwig_array(item.iqwig.iqwig_overall_conclusion[sub_key].iqwig_extent_probability, 'title'+$i18n.locale)"></span>
                                        </template>
                                        <span v-else-if="item.iqwig && item.iqwig.title" v-html="item.iqwig.title"></span>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_015'" :code="column.code">
                                        <template v-if="item.iqwig && item.iqwig.iqwig_overall_conclusion && item.iqwig.iqwig_overall_conclusion[sub_key]"> 
                                            {{check_empty_with_locale(iqwig_array(item.iqwig.iqwig_overall_conclusion[sub_key].iqwig_extent_probability, 'iqwig_probability_id'), 'iqwig_probability_id_')}}
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_016'" :code="column.code">
                                        <span v-if="item.iqwig && item.iqwig.iqwig_overall_conclusion && item.iqwig.iqwig_overall_conclusion[sub_key]" :class="color_iqwig(item.iqwig.iqwig_overall_conclusion[sub_key].iqwig_added_benefit_overall_id)">
                                            {{check_empty_with_locale(item.iqwig.iqwig_overall_conclusion[sub_key].iqwig_added_benefit_overall_id ,'iqwig_added_benefit_overall_id_')}}
                                        </span>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_017'" :code="column.code">
                                        <span :class="color_type_limitation(item.prioritis_hta_index)" v-if="sub_key === 0">{{check_empty_with_locale(item.prioritis_hta_index,'conclusion.')}}</span>
                                    </td>                       
                                    <template v-else-if="column.Status && column.code == 'iqwig_018'" colspan="4"  :code="column.code">
                                        <td :key="column.code+'_019'" :code="'iqwig_019'">
                                            <Readmore v-if="sub_key === 0 && $i18n.locale === 'de'" :longText="item.target_population_de" />
                                            <Readmore v-else-if="sub_key === 0 && $i18n.locale !== 'de'" :longText="item.target_population" />
                                        </td>
                                        <td :key="column.code+'_020'" :code="'iqwig_020'">
                                            <Readmore v-if="sub_key === 0 && $i18n.locale === 'de'"  :longText="item.prescription_and_delivery_conditions_de" />
                                            <Readmore v-else-if="sub_key === 0 && $i18n.locale !== 'de'" :longText="item.prescription_and_delivery_conditions" />
                                        </td>
                                        <td :key="column.code+'_021'" :code="'iqwig_021'">
                                            <Readmore v-if="sub_key === 0 && $i18n.locale === 'de'" :longText="item.access_scheme_de" />
                                            <Readmore v-else-if="sub_key === 0 && $i18n.locale !== 'de'" :longText="item.access_scheme" />
                                        </td>
                                        <td :key="column.code+'_022'" :code="'iqwig_022'">
                                            <Readmore v-if="sub_key === 0 && $i18n.locale === 'de'" :longText="item.outcome_level_de"/>
                                            <Readmore v-else-if="sub_key === 0 && $i18n.locale !== 'de'" :longText="item.outcome_level"/>
                                        </td>
                                    </template>
                                    <td v-else-if="column.Status && column.code == 'iqwig_023'" :code="column.code">
                                        <Readmore :longText="item.rationale_and_comments_html_en" v-if="sub_key === 0"/>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_024'" :code="column.code">
                                        <span v-html="item.iqwig.iqwig_commission.name" v-if="sub_key === 0"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_025'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.guidance_id)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_026'" :code="column.code">
                                        <template v-if="$i18n.locale === 'de' && sub_key === 0"><Readmore :longText="item['indication_de']"/></template>
                                        <template v-else-if="$i18n.locale !== 'de' && sub_key === 0"><Readmore :longText="item['indication_en']"/></template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_027'" :code="column.code">
                                        <span v-if="sub_key === 0 && $i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                        <span v-else-if="sub_key === 0 && $i18n.locale !== 'fr'" v-html="check_empty(array(item.classification_en))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_028'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.mk_orphelin)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_029'" :code="column.code">
                                        <span v-html="nct_number(item.nct_numbers)" v-if="sub_key === 0" @click="drug_id = item.drug_id"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_030'" :code="column.code">
                                        <span v-html="check_empty(treatment_line(item.treatment_line))" v-if="sub_key === 0"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_031'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.specificity)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'iqwig_032'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.administration)}}</template>
                                    </td>
                                    <template v-else-if="column.Status && column.code == 'iqwig_033'" :code="column.code">
                                        <td :key="column.code+'_034'" :code="'iqwig_034'">
                                            <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.hta_process_date))}}</template>
                                        </td>
                                        <td :key="column.code+'_035'" :code="'iqwig_035'">
                                            <span v-html="hta_process_status(item, 'hta_decision.')" v-if="sub_key === 0"></span>
                                        </td>
                                        <td :key="column.code+'_036'" :code="'iqwig_036'">
                                            <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.rembursement_process_date))}}</template>
                                        </td>
                                        <td :key="column.code+'_037'" :code="'iqwig_037'">
                                            <span v-html="rembursement_status(item.reimbursement_decision, 'reimbursement_decision.')" v-if="sub_key === 0"></span>
                                        </td>
                                    </template>
                                    <template v-else-if="column.Status && column.code == 'iqwig_038'" :code="column.code">
                                        <td :key="column.code+'_039'" :code="'iqwig_039'">
                                            <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)" v-if="sub_key === 0"></span>
                                        </td>
                                        <td :key="column.code+'_040'" :code="'iqwig_040'">
                                            <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)" v-if="sub_key === 0"></span>
                                        </td>
                                        <td :key="column.code+'_041'" :code="'iqwig_041'">
                                            <span v-html="datediff(item.ma_date, item.rembursement_process_date)" v-if="sub_key === 0"></span>
                                        </td>
                                    </template>
                                
                                </template>

                            </tr>
                        </template>
                    </template>
                    
                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>  
        <ModalHTAstatus :item="item_hta_status" />
        <ModalEssaisClinique :id="drug_id" agency="Iqwig" />    
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue'
import { isAdmin, column_status, hta_process_status, rembursement_status, hta_process_status_2, 
        rembursement_status_2, overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY, 
        check_empty, nct_number, treatment_line, datediff, array, color_type_limitation, odd_even_border, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Iqwig",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },
    data : function() {
        return { 
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
     },
    methods: {
        overview_regulatory_status,
        hta_process_status,
        rembursement_status,
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_type_limitation,
        odd_even_border,
        check_empty_with_locale,
        max_row(param){
            let temp = []
            temp.push(1)
            if(param && param.iqwig_overall_conclusion){
                temp.push(Object.keys(param.iqwig_overall_conclusion).length)
            }
            return Math.max(...temp)
        },
        iqwig_array(param, name){ 
            let txt = ''   
             if(param){        
                for(const val of param){
                    txt += val[name] ? val[name] : ""                
                }       
            }
            return  (txt.length == '') ? '-' : txt
        },

        column_status ,
        hta_process_status_2,
        rembursement_status_2,

        getResults(page = 1) {
            console.log(page)
        },
        hta_status_modal(item){
            this.item_hta_status = item
        },
        color_iqwig(param){
            let status = {
                    '2' : 'green',
                    '3' : 'green',
                    '4' : 'green',
                    '5' : 'green',
                    '6' : 'orange',
                    '7' : 'red',
            }
            return status[param]
        },
        modifyPage

    },
    
}
</script>

<style scoped>
.odd { 
    background-color : #fbfbff !important;
}
.even { 
    background-color : #fff !important;
}

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>